import { useMemo } from "react";
// eslint-disable-next-line import/no-unresolved
import { useIgPrices as useIntelligemsPrices } from "@intelligems/headless/gatsby";

import { createCookie, getAllUrlParams, getCookie } from "../context/helpers";

function useIgPrices({
  productId,
  variantId,
  originalPrice,
  originalCompareAtPrice,
  currencyCode,
}) {
  // We use &ig=off to disable Intelligems prices
  // If utm_source is google, we disable Intelligems prices for 1 day
  const shouldUseIntelligems = useMemo(() => {
    const queryParams = getAllUrlParams();

    let hasDisableIgCookie = getCookie("ig_disabled");
    if (queryParams.utm_source === "google") {
      if (!hasDisableIgCookie) {
        createCookie("ig_disabled", true, 1);
        hasDisableIgCookie = true;
      }
    }
    return queryParams.ig !== "off" && !hasDisableIgCookie;
  }, []);
  console.log(
    {
      productId,
      variantId,
      originalPrice,
      originalCompareAtPrice,
      currencyCode,
    },
    " => useIgPrices - PROPS"
  );

  const igPrices = useIntelligemsPrices({
    productId,
    variantId,
    originalPrice,
    originalCompareAtPrice,
    currencyCode,
  });
  console.log(igPrices, " => igPrices");
  console.log("=========");
  if (shouldUseIntelligems) {
    return igPrices;
  }

  return null;
}

export default useIgPrices;
